<template>
	<div>


		<el-dialog title="学生信息编辑" :visible.sync="isShowModal" custom-class="cus_dialog" width="1000px"
			:close-on-click-modal="false" @close="closeDialog">
			<div style="width: 100%; margin: 0 auto;">
				<el-form ref="form" :model="stuBaseInfo" label-width="80px">
					<table class="dttable" style="width: 100%;">
						<tr>
							<td colspan="4" class="tdheader">基础信息</td>

						</tr>
						<tr>
							<td class="tdheader">姓名</td>
							<td style="width:30%">{{ stuinfo.stu_name }}</td>
							<td class="tdheader">性别</td>
							<td style="width:30%">{{ stuinfo.gender == 1 ? '男' : '女' }}</td>
						</tr>
						<tr>
							<td class="tdheader">学号</td>
							<td>{{ stuinfo.stu_code }}</td>
							<td class="tdheader">身份证号</td>
							<td>{{ stuinfo.idcard_encrypt }}</td>
						</tr>
						<tr>
							<td class="tdheader">专业</td>
							<td>{{ stuinfo.zhuanye_alias }}</td>
							<td class="tdheader">班级</td>
							<td>{{ stuinfo.class_name }}</td>
						</tr>
						<tr>
							<td class="tdheader">学历</td>
							<td> <el-select clearable v-model="stuBaseInfo.xueli" placeholder="请选择学历" style="width:100%">
									<el-option label="专科" value="专科"></el-option>
									<el-option label="中技" value="中技"></el-option>
									<el-option label="高中、中职" value="高中、中职"></el-option>
								</el-select></td>
							<td class="tdheader">届次</td>
							<td>{{ stuinfo.jc_name }}</td>
						</tr>
						<tr>
							<td class="tdheader">民族</td>
							<td>

								<el-select clearable v-model="stuBaseInfo.minzu" placeholder="请选择民族" style="width:100%">
									<el-option label="汉族" value="汉族"></el-option>
									<el-option label="回族" value="回族"></el-option>
									<el-option label="其他" value="其他"></el-option>
								</el-select>
							</td>
							<td class="tdheader">政治面貌</td>
							<td>
								<el-select clearable v-model="stuBaseInfo.zzmm" placeholder="请选择政治面貌" style="width:100%">
									<el-option label="共青团员" value="共青团员"></el-option>
									<el-option label="群众" value="群众"></el-option>
								</el-select>
							</td>
						</tr>
						<tr>
							<td class="tdheader">技能等级</td>
							<td>
								<el-select clearable v-model="stuBaseInfo.skill_lvl" placeholder="请选择技能等级"
									style="width:100%">
									<el-option label="高级" value="高级"></el-option>
									<el-option label="技师" value="技师"></el-option>
									<el-option label="中级" value="中级"></el-option>
								</el-select>
								<!--  -->
							</td>
							<td class="tdheader">电话</td>
							<td><el-input v-model="stuBaseInfo.phone" style="width:100%"></el-input></td>
						</tr>
						<tr>
							<td class="tdheader">家长电话</td>
							<td>
								<el-input v-model="stuBaseInfo.jhr1phone" style="width:100%"></el-input>
							</td>
							<td class="tdheader">电子邮箱</td>
							<td>
								<el-input v-model="stuBaseInfo.dzyx" style="width:100%"></el-input>
							</td>
						</tr>
						<tr>
							<td class="tdheader">家庭住址</td>
							<td colspan="3">
								<el-input v-model="stuBaseInfo.jtzz" style="width:100%"></el-input>
							</td>

						</tr>
						<tr>
							<td colspan="4" class="tdheader">

								<div style="float: left; opacity: 0;">
									<el-button type="primary" icon="el-icon-s-claim" size="small">保存基本信息</el-button>
								</div>

								就业信息

								<div style="float: right;">
									<el-button type="primary" icon="el-icon-s-claim" size="small" :loading="loading"
										@click="saveBase">保存基本信息</el-button>
								</div>
							</td>

						</tr>

						<tr>
							<td class="tdheader">毕业去向</td>
							<td>
								<el-select clearable v-model="EditItem.byqx" placeholder="请选择毕业去向" style="width:100%">
									<el-option label="就业协议、实习协议形式就业" value="就业协议、实习协议形式就业"></el-option>
									<el-option label="签劳动合同形式就业" value="签劳动合同形式就业"></el-option>
									<!-- <el-option label="其他录用形式就业" value="其他录用形式就业"></el-option> -->
									<el-option label="升学" value="升学"></el-option>
									<el-option label="应征义务兵" value="应征义务兵"></el-option>
									<el-option label="出国、出境" value="出国、出境"></el-option>
									<el-option label="自主创业" value="自主创业"></el-option>
									<el-option label="自由职业" value="自由职业"></el-option>
									<el-option label="国家基层项目" value="国家基层项目"></el-option>
									<el-option label="不就业拟升学" value="不就业拟升学"></el-option>
									<el-option label="其他暂不就业" value="其他暂不就业"></el-option>
								</el-select>
							</td>
							<td class="tdheader" @click="showChouti">单位名称</td>
							<td>
								<div style="position: relative;">
									<el-input v-model="EditItem.firm_name" style="width:100%"></el-input>
									<div class="inpage" @click.stop="showChouti"
										style="position: absolute; width: 100%; height: 100%; top: 0;"></div>
								</div>


							</td>
						</tr>

						<tr>
							<td class="tdheader">组织代码</td>
							<td>
								{{ EditItem.enterInfo ? EditItem.enterInfo.creditCode : '' }}
							</td>
							<td class="tdheader">单位性质</td>
							<td>
								{{ EditItem.enterInfo ? EditItem.enterInfo.companyOrgType : '' }}
							</td>
						</tr>

						<tr>
							<td class="tdheader">单位行业</td>
							<td>
								{{ EditItem.enterInfo ? EditItem.enterInfo.industry : '' }}
							</td>
							<td class="tdheader">单位所在地</td>
							<td>
								<el-cascader v-model="EditItem.dwszd" style="width:100%;" @change="selectCity"
									:props="treeProps" clearable :options="areas" placeholder="请选择地区">
								</el-cascader>
							</td>
						</tr>
						<tr>
							<td class="tdheader">工作职位类别</td>
							<td>
								<el-select clearable v-model="EditItem.jobtype" placeholder="请选择工作职位类别" style="width:100%">
									<el-option label="工程技术人员" value="工程技术人员"></el-option>
									<el-option label="商业和服务业人员" value="商业和服务业人员"></el-option>
									<el-option label="科学研究人员" value="科学研究人员"></el-option>
									<el-option label="办事人员和有关人员" value="办事人员和有关人员"></el-option>
									<el-option label="军人" value="军人"></el-option>
									<el-option label="文学艺术工作人员" value="文学艺术工作人员"></el-option>
									<el-option label="经济业务人员" value="经济业务人员"></el-option>
									<el-option label="体育工作人员" value="体育工作人员"></el-option>
									<el-option label="生产和运输设备操作人员" value="生产和运输设备操作人员"></el-option>
									<el-option label="农林牧渔业技术人员" value="农林牧渔业技术人员"></el-option>
									<el-option label="教学人员" value="教学人员"></el-option>
									<el-option label="卫生专业技术人员" value="卫生专业技术人员"></el-option>
									<el-option label="新闻出版和文化工作人员" value="新闻出版和文化工作人员"></el-option>
									<el-option label="其他专业技术人员" value="其他专业技术人员"></el-option>
									<el-option label="其他人员" value="其他人员"></el-option>
								</el-select>
							</td>
							<td class="tdheader">专业是否对口</td>
							<td>
								<el-radio-group v-model="EditItem.duikou">
									<el-radio label="是">是</el-radio>
									<el-radio label="否">否</el-radio>

								</el-radio-group>
							</td>
						</tr>
						<tr>
							<td class="tdheader">是否有劳动合同</td>
							<td>
								<el-radio-group v-model="EditItem.hetong">
									<el-radio label="是">是</el-radio>
									<el-radio label="否">否</el-radio>
								</el-radio-group>
							</td>
							<td class="tdheader">是否有三险五险</td>
							<td>
								<el-radio-group v-model="EditItem.shebao">
									<el-radio label="是">是</el-radio>
									<el-radio label="否">否</el-radio>
								</el-radio-group>
							</td>
						</tr>
						<tr>
							<td class="tdheader">是否有公积金</td>
							<td>
								<el-radio-group v-model="EditItem.gongjijin">
									<el-radio label="是">是</el-radio>
									<el-radio label="否">否</el-radio>
								</el-radio-group>
							</td>
							<td class="tdheader">就业是否满意</td>
							<td>
								<el-radio-group v-model="EditItem.manyidu">
									<el-radio label="是">是</el-radio>
									<el-radio label="否">否</el-radio>
								</el-radio-group>
							</td>
						</tr>
						<tr>
							<td class="tdheader">企业联系人</td>
							<td>
								<el-input v-model="EditItem.firm_principal" style="width:100%"></el-input>
							</td>
							<td class="tdheader">企业联系人电话</td>
							<td>
								<el-input v-model="EditItem.tel" style="width:100%"></el-input>
							</td>
						</tr>
						<tr>
							<td class="tdheader">单位属于</td>
							<td>
								<el-select clearable v-model="EditItem.enter_type" placeholder="请选择单位属于" style="width:100%">
									<el-option label="国防军工" value="国防军工"></el-option>
									<el-option label="世界500强" value="世界500强"></el-option>
									<el-option label="民营500强" value="民营500强"></el-option>
									<el-option label="上市公司" value="上市公司"></el-option>
									<el-option label="教育培训" value="教育培训"></el-option>
									<el-option label="基础教育" value="基础教育"></el-option>
									<el-option label="其他" value="其他"></el-option>

								</el-select>
							</td>
							<td class="tdheader">税后月工资（元/月）</td>
							<td>
								<el-input type="number" v-model="EditItem.salary" style="width:100%"></el-input>
							</td>
						</tr>
						<tr>

							<td class="tdheader">就业材料</td>
							<td colspan="3">
								<div style="display:inline-block; margin-right:10px;vertical-align: top; position: relative;"
									v-for="(img, idx) in EditItem.files" :key="idx">
									<el-image style="width: 100px; height: 100px" :src="img.fileUrl || img.src"
										:preview-src-list="[img.fileUrl || img.src]" img="cover"></el-image>

									<div class="imgremove" @click="removeImg(idx)"><i class="el-icon-remove"></i></div>
								</div>

								<div style="display:inline-block;vertical-align: top;">

									<el-upload style="display: inline-block; margin-right: 20px" action=""
										:http-request="uploadOss" :on-success="uploadFiles" :show-file-list="false"
										name="image" accept="image/png, image/jpeg">
										<div class="imgaddbtn">
											＋
										</div>
									</el-upload>
								</div>
							</td>
						</tr>


					</table>

					<div style="text-align: right; padding-top: 20px;">
						<el-button type="primary" icon="el-icon-s-claim" :loading="loading" @click="saveForm">保存</el-button>
					</div>




				</el-form>
			</div>
		</el-dialog>

		<el-drawer title="请输入企业全称" size="30%" :visible.sync="drawer" direction="rtl" @close="drawer = false">
			<div style="padding:0 20px">
				<el-input placeholder="请输入请输入企业全称" :autofocus="true" v-model="keyword" class="input-with-select">

					<el-button type="primary" slot="append" icon="el-icon-search" @click="searchEnter"></el-button>
				</el-input>
			</div>

			<div>
				<div v-for="(ent, i) in EnterList" :key="i" class="enteritem" @click="setEnter(ent)">
					<div> {{ ent.name }}</div>
					<div> {{ ent.creditCode }}</div>
				</div>

			</div>

		</el-drawer>

	</div>
</template>


<script>
import area from "../../utils/arae_value.js"

export default {
	props: ["stuinfo"],
	components: {

	},
	data() {
		return {
			drawer: false,
			keyword: "",
			EnterList: [],
			EditItem: {
				byqx: '',
				firm_name: "",
				firm_num: "",
				address: "",
				firm_principal: "",
				tel: "",
				dwszd: "",
				dwszd_name: "",
				jobtype: "",
				duikou: "",
				hetong: "",
				shebao: "",
				gongjijin: "",
				manyidu: "",
				salary: "",
				enter_type: "",
				files: []
			},
			stuBaseInfo: {},
			isShowModal: true,
			loading: false,
			treeProps: {
				children: 'children',
				label: 'label',
				value: 'value',
				emitPath: false,
			},
			areas: [],

		}
	},

	mounted() {
		for (let p of area) {
			if (p.children) {
				for (let c of p.children) {
					delete c.children
				}
			}

		}
		this.areas = area
		this.stuBaseInfo = { ...this.stuinfo }
		this.getJobInfo()
	},
	methods: {

		getJobInfo() {
			this.$http.post("/api/byqx_self_one", { stu_id: this.stuBaseInfo.id }).then(res => {
				if (res.data) {
					res.data.files = JSON.parse(res.data.files)
					this.EditItem = res.data
				}

			})
		},
		uploadFiles(e) {
			if (!this.EditItem.files) {
				this.EditItem.files = []
			}
			this.EditItem.files.push(e)
		},
		removeImg(idx) {
			this.EditItem.files.splice(idx, 1)
		},
		closeDialog() {
			this.$$parent(this, "editDialogShow", false)
		},
		saveBase() {
			let data = { stu_base: this.stuBaseInfo }
			this.$http.post("/api/njjs_save_base", data).then(res => {
				this.$message({
					message: "学生信息保存成功!",
					type: 'success',
					customClass: "toast",
					duration: 5000
				});
				this.loading = false
				this.$$parent(this, "getStudents")

			})
		},

		saveForm() {

			if (this.EditItem.byqx != '应征义务兵' && this.EditItem.byqx != '不就业拟升学' && this.EditItem.byqx != '其他暂不就业') {
				if (!this.EditItem.firm_name.trim()) {
					this.$message({
						message: "请输入企业全称",
						type: 'error',
						customClass: "toast",
						duration: 2000
					});
					return
				}
			}

			if (this.EditItem.byqx != '升学' && this.EditItem.byqx != '应征义务兵' && this.EditItem.byqx != '不就业拟升学' && this.EditItem.byqx != '其他暂不就业') {
				if (!this.EditItem.dwszd) {
					this.$message({
						message: "请选择单位所在地",
						type: 'error',
						customClass: "toast",
						duration: 2000
					});
					return
				}
				if (!this.EditItem.jobtype.trim()) {
					this.$message({
						message: "请选择职位类别",
						type: 'error',
						customClass: "toast",
						duration: 2000
					});
					return
				}
				if (!this.EditItem.duikou) {
					this.$message({
						message: "请选择专业是否对口",
						type: 'error',
						customClass: "toast",
						duration: 2000
					});
					return
				}
				if (!this.EditItem.shebao) {
					this.$message({
						message: "请选择是否有三线五险",
						type: 'error',
						customClass: "toast",
						duration: 2000
					});
					return
				}
				if (!this.EditItem.gongjijin) {
					this.$message({
						message: "请选择是否有公积金",
						type: 'error',
						customClass: "toast",
						duration: 2000
					});
					return
				}
				if (!this.EditItem.manyidu) {
					this.$message({
						message: "请选择就业满意度",
						type: 'error',
						customClass: "toast",
						duration: 2000
					});
					return
				}

			}


			//检查岗位有没有填写
			if (this.EditItem.byqx != '不就业拟升学' && this.EditItem.byqx != '其他暂不就业') {
				if (this.EditItem.files.length == 0) {
					this.$message({
						message: "请上传证明文件",
						type: 'error',
						customClass: "toast",
						duration: 2000
					});
					return
				}
			} else {
				this.EditItem.files=[]
			}

			this.loading = true
			let data = { ...this.EditItem }
			data.stu_id = this.stuBaseInfo.id
			data.stu_base = this.stuBaseInfo
			this.$http.post("/api/t_sch_byqx_self_edit", data).then(res => {
				this.$message({
					message: "信息登记成功!",
					type: 'success',
					customClass: "toast",
					duration: 5000
				});
				this.loading = false
				this.$$parent(this, "getStudents")



			})


		},
		checkTyc() {
			if (this.EditItem.firm_name.trim()) {
				this.$http.post("/api/tyc_search", { enter_name: this.EditItem.firm_name }).then(res => {
					if (res.data) {
						this.EditItem.firm_name = res.data.name
						this.EditItem.address = res.data.regLocation
						this.EditItem.firm_num = res.data.creditCode
					}
				})
			}
		},

		selectCity(e) {

			for (let a of area) {
				if (a.children) {
					for (let b of a.children) {
						if (b.value == this.enterInfo.dwszd) {
							this.EditItem.dwszd_name = a.label + "," + b.label

							return
						}

					}
				}
			}

		},
		showChouti(e) {
			console.log(1)
			this.drawer = true
		},
		searchEnter() {
			if (this.keyword.trim()) {
				this.$http.post("/api/tyc_search_list", { enter_name: this.keyword.trim() }).then(res => {
					if (res.data) {
						this.EnterList = res.data
					}
				})
			}
		},
		setEnter(e) {
			this.EditItem.firm_name = e.name
			this.checkTyc()
			this.drawer = false
		},

	}

}

</script>
<style scoped="scoped">
.remove {
	width: 30px;
	height: 30px;
	position: absolute;
	top: -10px;
	right: -10px;
	background-color: #fff;
	border-radius: 50%;
	overflow: hidden;
}

.remove img {
	width: 100%;
	height: 100%;
}

.add {
	width: 40px;
	height: 40px;
	margin: 0 auto;
}

.add img {
	width: 100%;
	height: 100%;
}

.imgaddbtn {
	width: 100px;
	height: 100px;
	line-height: 100px;
	text-align: center;
	border: 1px dotted #ccc;
	font-size: 30px;
	cursor: pointer;
}

.imgremove {
	width: 20px;
	height: 20px;
	line-height: 20px;
	text-align: center;

	cursor: pointer;
	position: absolute;
	top: -5px;
	right: -5px;
	color: #ccc;
}

.imgremove:hover {
	color: orange;
}

.enteritem {
	padding: 10px 20px;
	border-bottom: 1px solid #eee;
}

.enteritem:hover {
	background-color: #eee;
	cursor: pointer;
}
</style>
